import './App.css';
// import ServerInfo from './components/serverInfo';

function App() {
    return (
    <div className="App">
        <header className="App-header">
            <h1>Dashboard</h1>

            {/*<ServerInfo serverURL={"http://localhost:3000"} />*/}
            {/*<ServerInfo serverURL={"http://localhost:300"}/>*/}
        </header>
    </div>
)
;
}

export default App;
